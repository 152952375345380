import React from "react";

export default function Navbar() {
  return (

    
      <div className="relative container mx-auto flex justify-center md:justify-center mx-auto mt-10">
        {/* Logo */}
        <div className="items-center">
          <img src="images/hempmed3.png" className="object-center md:object-top" alt="HEMPMED PHARMA" />
        </div>

       
      </div>

  );
}
