import React from "react";

export default function Testimonials() {
  return (
    <section id="test" className="xl:hidden opacity-0">
      <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
        {/* Heading */}
        <h2 className="text-4xl font-bold text-center">
          
        </h2>

        <div className="flex flex-col mt-24 md:flex-row md:space-x-6">

          <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">

            <h5 className="text-lg font-bold"></h5>
            <p className="text-sm text-darkGrayishBlue">

            </p>
          </div>

          


        </div>


      </div>
    </section>
  );
}
