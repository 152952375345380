import React from "react";

export default function Footer() {
  return (
  
    <footer className="bg-veryLightGray fixed inset-x-0 bottom-0 mb-0">
    <div className="container justify-center md:justify-center flex flex-col-reverse justify-between px-2 py-2 mx-auto space-y-0 md:flex-row md:space-y-0 text-sm text-center">
    <div className="text-black md:block">© Copyright 2016 - 2022 |  SOLAR POWER MANAGEMENT S.R.L. - <a href="https://hempmedpharma.com/" className="hover:text-brightRed">Site Oficial</a>  |   Toate drepturile rezervate   |   Realizat de - <a href="http://interpersonal.ro" className="hover:text-brightRed" target="_blank" rel="noopener"><img src="images/interpersonalmain.png" alt="Interpesonal" className="w-36 inline" /></a></div></div>
    </footer>
  );
}
