import React from "react";

export default function Hero() {
  return (
    <section id="hero">
      {/* Flex container */}

         <div className="flex justify-center md:justify-center mx-auto rounded-xl bg-green-600 bg-opacity-20 my-2 mx-4">
          <h1 className="text-3xl text-white font-extrabold text-center md:text-3xl md:text-center my-5">
            De până la 10x mai eficiente decât uleiurile neozonate
          </h1>
         </div>
	 <div className="flex justify-center md:justify-center mx-auto rounded-xl bg-green-600 bg-opacity-20 my-2 mx-4">
          <h1 className="text-3xl text-white font-extrabold text-center md:text-3xl md:text-center my-5">
            82% dintre pacienți cumpără și a doua oară
          </h1>
         </div>
	  <div className="flex justify-center md:justify-center mx-auto rounded-xl bg-green-600 bg-opacity-20 my-2 mx-4">
          <h1 className="text-3xl text-white font-extrabold text-center md:text-3xl md:text-center my-5">
            Uleiuri Ozonate, PREMIUM, cu Brevet International
          </h1>
         </div>
    </section>
  );
}
