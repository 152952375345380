import React from "react";

export default function CTA() {
  return (
    <section id="cta" className="bg-brightGreen bg-opacity-60">
      {/* Flex container */}
		   <div className="container  items-center">
		                 <div className="container flex flex-col items-center my-5">
          <a
            href="https://www.hempmedpharma.com/toate-produsele"
            className="p-3 px-6 pt-2 text-brightRed font-extrabold bg-white rounded-full shadow-2xl baseline hover:bg-gray-300"
          >
            Accesati Selectia de Produse
          </a>
        </div>

        </div>

		 
    </section>
  );
}
